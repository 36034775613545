import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";
import "./index.css";

//ReactDOM.render(<App /> , document )


const root = createRoot(document.getElementById('root')); 
root.render(<App/>);